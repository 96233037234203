<template>
  <div class="article-detail">
    <!-- 上 -->
    <div class="cz">
      <span>
        <el-link
          target="_blank"
          :underline="false"
          icon="el-icon-delete"
          @click="dele"
          >删除</el-link
        ></span
      >

      <span
        ><el-link target="_blank" :underline="false" @click="edit"
          >关闭预览</el-link
        ></span
      >
    </div>
    <div>
      <div class="dw-center">
        <div class="ad-up content-wth">
          <!-- 导航 -->

          <!-- 标题 -->
          <div class="article-title">{{ article.title }}</div>
          <!-- 作者 时间-->
          <div class="font-14 author-time">
            <!-- 作者 -->
            <span
              >本文作者:<span class="author">{{
                article.authorName
              }}</span></span
            >
            <!-- 时间 -->
            <span class="time">{{ article.createTime }}</span>
          </div>
          <!-- 文章导语 -->
          <div class="intro font-14 ">{{ article.intro }}</div>
          <div style="text-align: center;margin-bottom:10px">
            所属类别:{{ articleSort }}
          </div>
          <div style="text-align:center;">
            <span class="xjkeyword-icon"></span>
            <span
              class="keyword"
              v-for="(item, index) in this.$route.params.keywordList"
              :key="index"
            >
              <span>{{ item }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 下 -->
    <div class="ad-down">
      <!-- 左 -->
      <div class="ad-left content-wth">
        <!-- 文章内容 -->
        <div class="content-info" v-html="article.content"></div>

        <el-button type="text" @click="open" v-show="df"
          ><i class="el-icon-paperclip"></i>{{ article.name }}</el-button
        >
        <br />
        <a :href="article.reference"></a>
        <!-- 相关文章 -->

        <!-- 文章点评 -->
      </div>
      <!-- 右 -->
    </div>
  </div>
</template>

<script>
import { showTimeFormat } from "@/js/common";
import { QUERY } from "@/services/dao.js";
import axios from "axios";
export default {
  data() {
    return {
      articleSort: "",
      article: {
        id: 1,
        accessory: "",
        authorName: "张帅",
        browseNum: "",
        collectNum: 0,
        commentNum: 0,
        concernNum: "0",
        content:
          "章内容文章内容文章内容文章内容文章内容文章内容 文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容 文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容 文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容 文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容 文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容 文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容 文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容 文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容文章内容",
        createTime: "2020-03-19 12:56",
        hotNum: "",
        img: "",
        intro:
          "一夜之间，无人不识新基建。5G是一场狂欢盛宴，但在短暂热潮后，5G产业也暴露出一些问题和挑战尚待解决。",
        keyword: "",
        praiseNum: 0,
        reference: "",
        theme_id: "",
        title: "5G，为什么是新基建之首？",
        type: "",
        df: false,
      },
      // article:{collectNum:0,praiseNum:0},
      imgUrl:
        "https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1586522731866&di=c8eb6a274e915136eab1b885ddc895ed&imgtype=0&src=http%3A%2F%2Fpic.feizl.com%2Fupload%2Fallimg%2F170615%2F143633G57-0.jpg",
      imgHeight: "98px",
      fontSize: "14px",
      author: {
        id: 15,
        name: "大哥哥",
        img:
          "https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1585823117222&di=6c973e4b2438d637bb89d06e72b4af2c&imgtype=0&src=http%3A%2F%2Ft7.baidu.com%2Fit%2Fu%3D3616242789%2C1098670747%26fm%3D79%26app%3D86%26f%3DJPEG%3Fw%3D900%26h%3D1350",
      },
      degree: "主笔",
      intro: "挑战尚待解决",
      articleType: "当月热门文章",
      list: [
        { id: 1, title: "AI 崛起的第九个年头，还有哪些大有可为的地方？" },
        { id: 2, title: "AI 崛起的第九个年头，还有哪些大有可为的地方？" },
      ],
      navList: [{ word: "正文" }],
    };
  },
  created() {
    console.log(this.$route.params);
    this.article.title = this.$route.params.form_message.title;
    this.article.reference = this.$route.params.form_message.reference;
    this.article.accessory = this.$route.params.form_message.accessory;
    this.init();
    this.article.authorName = this.$store.state.userinfo.name;
    this.article.content = this.$route.params.form_message.content;
    this.article.file = this.$route.params.form_message.file;
    this.article.name = this.$route.params.form_message.name;
    this.article.createTime = showTimeFormat(
      this.$route.params.form_message.createTime
    );
    console.log("this.article.createTime: ", this.article.createTime);
    this.article.keyword = this.$route.params.form_message.keyword;
    this.article.intro = this.$route.params.form_message.intro;
    if (
      this.article.file == "" ||
      this.article.file == null ||
      this.article.file == undefined
    ) {
      this.df = false;
    } else {
      this.df = true;
    }
  },

  methods: {
    open() {
      this.$alert("是否下载附件", "下载附件", {
        confirmButtonText: "确定",
        callback: (action) => {
          let urla = this.article.file;

          const formData = new FormData();
          formData.append("path", urla);
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          axios
            .post(
              this.api.LoginURL.concat("/resources/trading/download"),
              formData,
              {
                responseType: "blob", //服务器返回的数据类型
              }
            )
            .then((res) => {
              console.log(res);
              //此处有个坑。这里用content保存文件流，最初是content=res，但下载的test.xls里的内容如下图1，
              //检查了下才发现，后端对文件流做了一层封装，所以将content指向res.data即可
              //另外，流的转储属于浅拷贝，所以此处的content转储仅仅是便于理解，并没有实际作用=_=
              const content = res.data;
              const blob = new Blob([content]); //构造一个blob对象来处理数据
              const fileName = this.article.name;
              //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
              //IE10以上支持blob但是依然不支持download
              if ("download" in document.createElement("a")) {
                //支持a标签download的浏览器
                const link = document.createElement("a"); //创建a标签
                link.download = fileName; //a标签添加属性
                link.style.display = "none";
                link.href = URL.createObjectURL(blob);
                document.body.appendChild(link);
                link.click(); //执行下载
                URL.revokeObjectURL(link.href); //释放url
                document.body.removeChild(link); //释放标签
                updatedownload(this.id, this.download);
                this.$router.push({
                  path: "/resources_index",
                  query: {},
                });
              } else {
                //其他浏览器
                navigator.msSaveBlob(blob, fileName);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        },
      });
    },
    dele() {
      this.$router.push({ name: "Release", params: { userId: 123 } });
    },
    edit() {
      this.$router.push({ name: "Release", params: this.$route.params });
    },
    back() {
      this.$router.push({ name: "Release", params: this.$route.params });
    },
    // 根据主题 id 查询 主题title
    async init() {
      if (this.$route.params.form_message.theme != "") {
        let res = await QUERY(
          "post",
          "",
          "Theme(where:{id:{_eq:" +
            this.$route.params.form_message.theme +
            "}}) {title}"
        );
        // console.log(res)
        this.articleSort = res.data.Theme[0].title;
      }
    },
  },
};
</script>

<style scoped>
/* 公共 */
.cz {
  background-color: #ffffff !important;
  height: 74px;
  line-height: 104px;
  padding-left: 350px;
}
.cz span {
  margin-left: 71px;
}
.font-14 {
  font-size: 14px;
}

.collect-icon {
  height: 14px;
  width: 14px;
  /* background-image: url(../../assets/img/icon-collect.png); */
  display: inline-block;
}
.praise-icon {
  height: 18px;
  width: 18px;
  /* background-image: url(../../assets/icon/icon.png); */
  background-position-x: 629px;
  background-position-y: -44px;
  display: inline-block;
}
.home-icon {
  /* background-image: url(../../assets/icon/icon.png); */
  background-position-x: 502px;
  background-position-y: -75px;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.xinlang-icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  border: 0px solid rgba(155, 170, 182, 1);
  border-radius: 50%;
  /* background-image: url(../../assets/icon/icon.png); */
  background-position-x: 615px;
  background-position-y: -565px;
}
.weixin-icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  border: 0px solid rgba(155, 170, 182, 1);
  border-radius: 50%;
  /* background-image: url(../../assets/icon/icon.png); */
  background-position-x: 485px;
  background-position-y: -564px;
}
.fxadd-icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  border: 0px solid rgba(155, 170, 182, 1);
  border-radius: 50%;
  /* background-image: url(../../assets/icon/icon.png); */
  background-position-x: 549px;
  background-position-y: -564px;
}
.article-detail,
.dw-center {
  text-align: center;
}
.article-detail {
  background-color: #f7f7f7;
}

.article-detail .ad-down .ad-left,
.article-detail .ad-down .ad-right {
  display: inline-block;
  vertical-align: top;
}

/* 上 */
.article-detail > div:first-of-type {
  background: rgba(235, 235, 235, 1);
}
.article-detail .ad-up {
  background: rgba(235, 235, 235, 1);
}
.article-detail .ad-up .nav-bar span {
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: rgba(118, 118, 118, 1);
}
.article-detail .ad-up .nav-bar span:not(:first-of-type) {
  margin-left: 5px;
}
.article-detail .ad-up .article-title,
.article-detail .ad-up .author-time,
.article-detail .ad-up .intro {
  text-align: center;
}
.article-detail .ad-up .author-time {
  margin-top: 20px;
}
.article-detail .ad-up .intro {
  margin-top: 20px;
  padding-bottom: 20px;
}
.article-detail .author-time .time {
  margin-left: 100px;
}
.article-detail .article-title {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(49, 66, 78, 1);
  margin-top: 50px;
}
/* 下 */
.article-detail .ad-down {
  padding-top: 50px;
  position: relative;
  text-align: left;
}
.dw-center,
.article-detail .ad-down {
  width: 1124px;
  display: inline-block;
}
.dw-center {
  text-align: left;
}
/* 下-左 */
.article-detail .ad-down .ad-left .content-info {
}
.article-detail .ad-down .ad-left .collect-praise-share {
  position: relative;
  height: 90px;
  margin-top: 100px;
}
.article-detail .ad-down .ad-left .collect-praise-share .collect-praise {
  position: absolute;
  display: inline-block;
  left: 0px;
}
.article-detail
  .ad-down
  .ad-left
  .collect-praise-share
  .collect-praise
  .collect,
.article-detail
  .ad-down
  .ad-left
  .collect-praise-share
  .collect-praise
  .praise {
  display: inline-block;
  width: 108px;
  height: 40px;
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(210, 210, 210, 1);
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
}
.article-detail
  .ad-down
  .ad-left
  .collect-praise-share
  .collect-praise
  .praise {
  margin-left: 12px;
}
.article-detail .ad-down .ad-left .collect-praise-share .share {
  position: absolute;
  display: inline-block;
  right: 0px;
  height: 50px;
  line-height: 50px;
}
.article-detail .ad-down .ad-left .collect-praise-share .share span {
  vertical-align: middle;
  margin-left: 15px;
}
.article-detail .ad-down .ad-left .relation-article .relation-list {
  margin-top: 17px;
  height: 250px;
}

.article-detail .ad-down .ad-left .relation-article .key-word span {
  display: inline-block;
  padding: 2px 10px;
  min-width: 60px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(210, 210, 210, 1);
  border-radius: 14px;
  text-align: center;
}
.article-detail
  .ad-down
  .ad-left
  .relation-article
  .key-word
  span:not(:first-of-type) {
  margin-left: 10px;
}
.article-detail
  .ad-down
  .ad-left
  .relation-article
  .relation-list
  .relation-one {
  display: inline-block;
  width: 171px;
  height: 151px;
  vertical-align: top;
}
.article-detail
  .ad-down
  .ad-left
  .relation-article
  .relation-list
  .relation-one:not(:first-of-type) {
  margin-left: 33px;
}

/* 下-左-相关文章 */

/* 下-右 */
.article-detail .ad-down .ad-right {
  width: 307px;
  margin-left: 40px;
}
.article-detail .ad-down .ad-right .author {
  padding: 50px 20px 20px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(225, 225, 225, 1);
}
/* 最新文章 */
.newest {
  margin-top: 50px;
}
.newest .bt {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 34px;
}
.newest-list {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(225, 225, 225, 1);
  background: rgba(255, 255, 255, 1);
}
.newest-list .newest-one {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
  line-height: 30px;
}

.newest-list .newest-one:not(:first-of-type) {
  padding-top: 20px;
}
.newest-list .newest-one:not(:last-of-type) {
  border-bottom: 1px solid rgba(225, 225, 225, 1);
  padding-bottom: 20px;
}
.release-amount {
  text-align: center;
  border: 1px solid rgba(225, 225, 225, 1);
  margin-top: 50px;
  background: rgba(255, 255, 255, 1);
}
.release-amount > span {
  font-size: 16px;
  font-weight: 300;
  color: rgba(120, 120, 120, 1);
  line-height: 30px;
}
.release-amount > span > span {
  color: rgba(253, 110, 5, 1);
}

.keyword {
  display: inline-block;
  height: 20px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(210, 210, 210, 1);
  border-radius: 14px;
  padding: 3px 10px;
  line-height: 20px;
  vertical-align: middle;
  margin-left: 5px;
  font-size: 12px;
}
.xjkeyword-icon {
  height: 18px;
  width: 18px;
  background: url(~@/assets/img/xjwkeyword-icon.png) no-repeat;

  display: inline-block;
}
</style>
